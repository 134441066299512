import { Fragment, useEffect, useState } from "react";
import { useCallback } from "react";
import http from "../../utils/http";
import toast from "react-hot-toast";

import SlidingContent from "../../components/UI/SlidingContent";

const Price = () => {
  const [data, setData] = useState(null);

  const content = {
    pageId: 1,
    lis: data,
    title: (
      <span>
        Find <span className="text-[red]">your</span> property
      </span>
    ),
    subtitle: (
      <span className="flex flex-col gap-4 lg:gap-6 text-base md:text-xl">
        <span className="text-[#dbdbdb] text-base md:text-[1.48rem] px-4 sm:px-0">
          Don't know what you are looking for?{" "}
        </span>
        <h2 className="font-bold px-6 sm:p-0 text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl">
          Where would your <span className="text-[red]">property</span> be?
        </h2>
      </span>
    ),
    nextLink: "unit-type",
    input: true,
  };

  const getLocations = useCallback(() => {
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getLocations/`)
      .then((response) => {
        if (response.status === 200) {
          const results = response?.data?.results;
          setData(
            results.map((result) => {
              result.Name = result.Location;
              return result;
            })
          );
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  return (
    <Fragment>
      <SlidingContent content={content} isFetched={data}></SlidingContent>
    </Fragment>
  );
};
export default Price;
