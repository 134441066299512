const RightAngle = () => {
  return (
    <div className="absolute left-[3%] top-[11%] sm:left-[9%] sm:top-[12%] md:left-[18%] xl:left-[23%] md:top-[15%] flex flex-col items-center">
      <div className="absolute top-0 left-0">
        <div className="relative ml-3 sm:m-auto">
          <div className="absolute left-0 top-0 h-4 sm:h-5 w-16 sm:w-24 md:w-28 md:h-6 bg-[red] z-20"></div>
          <div className="absolute left-0 top-0 w-4 sm:w-5 h-16 sm:h-24 md:h-28 md:w-6 bg-[red] z-20"></div>
        </div>
      </div>
    </div>
  );
};
export default RightAngle;
