import { useContext, Fragment, useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Dropdown from "../../components/UI/Dropdown";
import http from "../../utils/http";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import SliderCard from "../Slider/SliderCard";

const SearchProperty = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { state: homeSearch } = useLocation();
  const [searchValue, setSearchValue] = useState(homeSearch || "");
  const [locations, setLocations] = useState([]);
  const [unitTypes, setUnitTypes] = useState([]);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [pageNo, setPageNo] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(window.screen.width <= 768 ? 1 : 16);
  const [uploadedDevelopers, setUploadedDevelopers] = useState(null);
  const [developers, setDevelopers] = useState([]);

  const navigate = useNavigate();
  const [pageNumbers, setPageNumbers] = useState([]);

  const state = {
    page: pageNo,
    ...(searchValue != "" && { name: searchValue }),
    ...(selectedLocation && { location: selectedLocation._id }),
    ...(selectedPropertyType && { unitType: selectedPropertyType._id }),
  };

  const getProperties = async (params) => {
    setLoading(true);

    const res = await http
      .get(`${process.env.REACT_APP_ATLAS_URI}/searchProperty/`, {
        params: { ...params, limit: limit, page: pageNo },
      })
      .then((response) => {
        const data = response.data.results;
        if (response.status === 200) {
          const list = [];
          // setPageNumbers([...Array(Math.ceil(data.count / 4))])
          const _developers = [];
          data.forEach((element) => {
            const developer = uploadedDevelopers.find(
              (d) => d._id === element.Project_Developer
            );
            _developers.push(developer);
            list.push({
              id: element._id,
              img: element.Images.length !== 0 ? element.Images[0] : null,
              title: element.Name,
              subtitle: element.Type,
              price: element.Price,
              contient: element.State,
              location: `${element.City}, ${element.Country}`,
              InstallmentYears: `${element.InstallmentYears} Years`,
              Delivery: `${element.Delivery}`,
              DownPayment: `${element.DownPayment}%`,
            });
          });
          setDevelopers(_developers);
          setData(list);
          setPageNumbers(
            Array.from(
              { length: Math.ceil(response.data.count / limit) },
              (v, k) => k + 1
            )
          );
          setCount(response.data.count);
          setLoading(false);
        } else {
          toast.error(response?.data?.error?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (uploadedDevelopers) {
      getProperties(state);
    }
  }, [uploadedDevelopers, pageNo]);

  useEffect(() => {
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getProjectDevelopers/`)
      .then((response) => {
        if (response.status === 200) {
          const results = response?.data?.results;
          setUploadedDevelopers(results);
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));

    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getLocations/`)
      .then((response) => {
        if (response.status === 200) {
          const results = response?.data?.results;
          setLocations(
            results.map((result) => {
              result.Name = result.Location;
              result.id = result._id;
              return result;
            })
          );
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getUnitTypes/`)
      .then((response) => {
        if (response.status === 200) {
          const results = response?.data?.results;
          setUnitTypes(results);
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));
  }, []);

  const searchChangeHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const propertyTypeChangeHandler = (propertyType) => {
    setSelectedPropertyType(propertyType);
  };

  const locationChangeHandler = (location) => {
    setSelectedLocation(location);
  };

  const searchClickHandler = (e) => {
    setPageNo(1);
    getProperties(state);
  };

  const leftClickHandler = () => {
    setLoading(true);
    setData(null);
    setPageNo((prev) => prev - 1);
  };

  const rightClickHandler = () => {
    setLoading(true);
    setData(null);
    setPageNo((prev) => prev + 1);
  };
  const selectPageHandler = (index) => {
    setLoading(true);
    setData(null);
    setPageNo(index + 1);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        searchClickHandler();
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [selectedLocation, selectedPropertyType, searchValue]);

  return (
    <Fragment>
      <div className="text-center px-6 sm:px-4 md:px-8 lg:px-10 xl:px-32 pt-6">
        <h2 className="font-semibold text-xl md:text-3xl lg:text-5xl px-4 sm:p-0 text-white">
          find the <span className="text-[red] font-bold">property</span> that
          <span className="text-[red] font-bold"> suits</span> you best
        </h2>
        <div className="grid grid-cols-[3fr,3fr] sm:grid-cols-[3fr,3fr,3fr,1fr] md:grid-cols-[4fr,3fr,4fr,1fr] lg:grid-cols-[4fr,3fr,4fr,1fr] gap-4 lg:gap-8 justify-center items-start mt-6 md:mt-8 lg:mt-10 mx-auto">
          <input
            type={"text"}
            id="Search"
            value={searchValue}
            className="border-2 rounded-full bg-transparent border-[red] text-lg pl-4 pr-3 py-3 justify-self-center cursor-pointer text-white outline-none w-full"
            placeholder="Search"
            onChange={searchChangeHandler}
          />
          <Dropdown
            content={unitTypes}
            selectedValue={{ id: 0, Name: "Property Type" }}
            onValueChange={propertyTypeChangeHandler}
          />
          <Dropdown
            content={locations}
            selectedValue={{ id: 0, Name: "Location" }}
            onValueChange={locationChangeHandler}
          />
          {/* Search button*/}
          <div
            className="border-2 rounded-full border-[red] w-fit px-3 py-2 justify-self-center cursor-pointer"
            onClick={searchClickHandler}
          >
            <i className="fa-solid fa-magnifying-glass text-2xl text-white"></i>
          </div>
        </div>
      </div>
      <div className="relative px-2 sm:px-4 lg:px-8">
        <h2 className="font-bold text-lg md:text-xl lg:text-2xl my-4 lg:mb-4 px-4 sm:p-0 grid-cols-3 w-full text-center text-gray-200">
          {count} results
        </h2>

        {/* Property Cards */}
        <div className="flex items-center gap-4">
          <i
            className="fa fa-chevron-left fa-2xl text-[red] cursor-pointer"
            aria-hidden="true"
            onClick={leftClickHandler}
          ></i>
          {data ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full">
              {data.map((data, index) => {
                return (
                  <SliderCard
                    data={data}
                    key={index}
                    projectDeveloper={developers[index]}
                    className="!w-full"
                  />
                );
              })}
            </div>
          ) : !data && loading ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full mt-4">
              {[...Array(12)].map((val, index) => (
                <div
                  className={`animate-pulse bg-transparent border border-gray-300 px-4 py-7 rounded-md mx-auto w-full font-avant-grade-semibold`}
                  key={index}
                >
                  <div className="w-full h-[180px] rounded-md bg-gray-300"></div>
                  <div className="px-4">
                    <div className="flex flex-col w-full mt-4 gap-2 font-avant-grade-bold">
                      <div className="flex justify-between items-center">
                        <h2 className="text-xl font-bold py-4 bg-gray-300 w-36 rounded-md"></h2>
                      </div>
                      <span className="text-gray-500 py-3 bg-gray-300 rounded-md w-24"></span>
                    </div>
                    <div className="text-xl sm:text-2xl font-bold bg-gray-300 py-6 rounded-md px-24 mt-4 w-fit"></div>
                    <div className="flex gap-0 sm:gap-2 my-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="gray"
                        viewBox="0 0 24 24"
                        strokeWidth={0}
                        stroke=""
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                      <span className="text-sm sm:text-base py-2 bg-gray-300 w-24 rounded-md"></span>
                    </div>
                    <div className="flex gap-2 sm:gap-4 w-full">
                      <div className="flex items-center gap-2 py-2 pr-2">
                        <span className="w-6 h-6 bg-transparent rounded-md bg-gray-300" />
                        <span className="py-2 px-6 bg-gray-300 rounded-md"></span>
                      </div>
                      <div className="flex items-center gap-2 py-2 pr-2">
                        <div className="relative">
                          <i className="fa-solid fa-calculator text-xl text-gray-300"></i>
                        </div>
                        <span className="py-2 px-4 bg-gray-300 rounded-md"></span>
                      </div>
                    </div>
                    <div className="relative flex items-center gap-2 pl-2 w-full">
                      <i className="fas fa-calendar-alt text-xl text-gray-300"></i>
                      <span className="py-2 px-4 bg-gray-300 rounded-md"></span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            !data &&
            !loading && (
              <div className="w-full justify-center md:mt-12">
                <div className="text-white text-center w-full font-avant-grade-bold text-3xl pt-3">
                  No Results Found
                </div>
                <div className="my-6  text-white text-xl text-center font-avant-grade-bold">
                  Your search preferences have{" "}
                  <span className="text-red-600">(0) results</span>.
                </div>
                <div className="font-bold text-center text-3xl text-white w-full">
                  <span>Try again? </span>
                </div>
              </div>
            )
          )}
          <i
            className="fa fa-chevron-right fa-2xl text-[red] cursor-pointer"
            aria-hidden="true"
            onClick={rightClickHandler}
          ></i>
        </div>
        {/* Page Numbers */}
        <div className="flex items-center justify-center gap-4 w-full mt-4">
          {pageNumbers
            .slice(
              pageNo + 8 > pageNumbers.length
                ? pageNumbers.length - 8 >= 0
                  ? pageNumbers.length - 8
                  : 0
                : pageNo - 1,

              pageNo + 8 > pageNumbers.length ? pageNumbers.length : pageNo + 8
            )
            .map((pageNumber, index) => {
              return (
                <button
                  key={index}
                  className={`${
                    pageNo === pageNumber
                      ? "bg-[red] text-gray-50"
                      : "bg-gray-50 text-[#212020]"
                  } rounded-full font-semibold w-3 h-3 text-[8px]`}
                  onClick={() => selectPageHandler(pageNumber - 1)}
                >
                  {pageNumber}
                </button>
              );
            })}
        </div>
      </div>
    </Fragment>
  );
};
export default SearchProperty;
