import { Link } from "react-router-dom";

const Contact = (props) => {
  return (
    <div
      className={`${props.className} grid grid-cols-3 gap-2 sm:gap-3 w-full sm:w-fit font-cairo tracking-normal sm:tracking-wide`}
    >
      <a
        href="whatsapp://send?text=Lets chat!&phone=201012995408"
        target="_blank"
        className="flex items-center gap-1 px-4 sm:px-8 justify-center cursor-pointer bg-[red] text-white rounded-3xl py-1"
      >
        <i className="fa-brands fa-whatsapp text-xl text-white"></i>
        <span className={`${props.text} text-white text-sm text-center`}>
          Whatsapp
        </span>
      </a>
      <a
        href="tel:+201012995408"
        target="_blank"
        className="flex items-center gap-2 px-4 sm:px-8 justify-center cursor-pointer bg-[red] text-white rounded-3xl py-1"
      >
        <i className="fa-solid fa-phone text-xl"></i>

        <span className={`${props.text} text-white text-sm`}>Call</span>
      </a>
      <div
        onClick={() => { props.setShowModal(true) }}
        className="flex gap-2 py-2 px-4 sm:px-8 items-center cursor-pointer justify-center bg-[red] text-white rounded-3xl"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>

        <span className={`${props.text} text-white text-sm`}>Price</span>
      </div>
    </div>
  );
};
export default Contact;
