import { Fragment } from "react";
import SlidingContent from "../../components/UI/SlidingContent";
const Unit = () => {
  const content = {
    pageId: 3,
    title: (
      <span className="w-full">
        What is the <span className="text-[red]">price </span>
        range you have in mind?
      </span>
    ),
    lis: [
      { _id: 10000000, Name: "10 Million" },
      { _id: 20000000, Name: "20 Million" },
      { _id: 30000000, Name: "30 Million" },
      { _id: 40000000, Name: "40 Million" },
      { _id: 50000000, Name: "50 Million" },
    ],
    nextLink: "slider",
  };
  return (
    <Fragment>
      <SlidingContent content={content} isFetched></SlidingContent>
    </Fragment>
  );
};
export default Unit;
