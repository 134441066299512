import { Fragment, useEffect, useState, useCallback } from "react";
import toast from "react-hot-toast";
import http from "../../utils/http";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import DateList from "./DateList";
import Button from "../../components/UI/Button";
import { useForm } from "../../admin/hooks/form-hook";

const MeetingForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("+20");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  const timings = [
    { time: "9:00 AM", value: "9:00 AM" },
    { time: "10:00 AM", value: "10:00 AM" },
    { time: "11:00 AM", value: "11:00 AM" },
    { time: "12:00 PM", value: "12:00 PM" },
    { time: "3:00 PM", value: "15:00 PM" },
    { time: "5:00 PM", value: "17:00 PM" },
  ];

  const noOfDays = 12;

  const [formState, inputHandler] = useForm({
    Name: "",
    PhoneNumber: "",
    Date: "",
    Time: "",
  });

  const dates = [];
  const date = new Date();
  for (let day = 0; day < noOfDays; day++) {
    dates.push({
      date: date.setDate(date.getDate() + 1),
    });
  }

  const nameChangeHandler = (e) => {
    setName(e.target.value);
    inputHandler(e.target.id, e.target.value);
  };

  const phoneChangeHandler = (input) => {
    setPhone(input);
    inputHandler("PhoneNumber", input);
  };

  const dateChangeHandler = (date) => {
    inputHandler("Date", date.date);
    setSelectedDate(date.date);
  };

  const timeChangeHandler = (e) => {
    setSelectedTime(e.target.value);
    inputHandler(e.target.name, e.target.value);
  };

  useEffect(() => {
    inputHandler("Message", "meeting scheduled");
  }, []);

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!selectedDate || !selectedTime) {
      alert("Please fill out the remaining fields!");
      return;
    }

    const time = selectedTime.split(" ")[0];

    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    const newTime = new Date(null, null, null, hours, minutes);
    http
      .post(`${process.env.REACT_APP_ATLAS_URI}/addContact/`, {
        Name: formState.Name,
        PhoneNumber: formState.PhoneNumber,
        PreferedLocation: formState.PreferedLocation,
        Message: formState.Message,
        Date: new Date(formState.Date),
        Time: new Date(newTime),
      })
      .then((response) => {
        if (response.status === 200) {
          setName("");
          setSelectedTime(null);
          setSelectedDate(null);
          // setMessage("");
          setPhone("+20");
          toast.success(response?.data?.message);
        } else toast.error(response.data.error.message);
      })
      .catch((err) => toast.error(err.message));
  };

  return (
    <Fragment>
      <div className="relative w-[95%] md:w-11/12 lg:w-10/12 px-6 sm:px-12 rounded-lg m-auto form-shadow h-fit tracking-wide">
        <h2 className="text-center md:text-left font-bold text-white sm:p-0 text-xl sm:text-2xl lg:text-3xl my-2 md:my-4 lg:my-10">
          Your Information
        </h2>
        <form
          action=""
          onSubmit={onSubmitHandler}
          className="flex flex-col gap-4 2xl:gap-6"
        >
          <div className="flex flex-wrap lg:items-end gap-3 md:gap-6 lg:gap-8">
            <div className="w-full sm:w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/4">
              <label
                htmlFor="Name"
                className="block mb-2 text-sm sm:text-base md:text-lg text-white font-medium"
              >
                Your Name
              </label>
              <input
                type="text"
                value={name}
                id="Name"
                className="text-lg leading-none border-transparetn bg-white border text-[#212020] outline-0 rounded-lg block w-full p-2.5 focus:border-[red]"
                placeholder="John"
                required
                onChange={nameChangeHandler}
              />
            </div>
            <div className="w-full sm:w-4/5 md:w-3/5 lg:w-2/5 xl:w-1/4">
              <label
                htmlFor="PhoneNumber"
                className="block mb-2 text-sm sm:text-base md:text-lg text-white font-medium"
              >
                Phone Number
              </label>
              <PhoneInput
                specialLabel={""}
                id={"PhoneNumber"}
                country={"th"}
                value={phone}
                onChange={phoneChangeHandler}
                containerClass="bg-none"
                inputClass="focus:border-[red] border !bg-[white]"
                buttonStyle={{
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "1.5rem",
                  paddingLeft: "0.5rem",
                }}
                inputStyle={{
                  backgroundColor: "transparent",
                  width: "100%",
                  display: "block",
                  padding: "1.4rem 3rem",
                  fontSize: "1.125rem",
                  borderRadius: "0.5rem",
                }}
              />
            </div>
          </div>
          <div>
            <h3 className="text-white text-sm sm:text-base md:text-lg font-semibold">
              Select date
            </h3>

            {/* Date List */}
            <DateList
              dates={dates}
              dateChangeHandler={dateChangeHandler}
              selectedDate={selectedDate}
            />
          </div>
          <div className="flex flex-col flex-wrap sm:flex-row-reverse gap-4 sm:gap-6 md:gap-10 justify-end sm:items-end mb-6">
            <div className="flex flex-col gap-3">
              <h2 className="text-sm sm:text-base md:text-lg font-semibold text-gray-100">
                Select time
              </h2>
              <select
                className="block bg-white border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-blue-800 w-fit"
                name="Time"
                id="Time"
                defaultValue="9:00 AM"
                onChange={timeChangeHandler}
              >
                {timings.map((time, index) => (
                  <option value={time.value} key={index}>
                    {time.time}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex justify-center gap-4 md:gap-8 md:justify-end mt-4 sm:mt-8 md:m-0">
            <Button className="bg-[#ff4747] text-gray-100" type="submit">
              Request this time
            </Button>
            <a
              href="https://wa.me/201012995408?text=Lets%20chat!%20-%20from%20Byoutak"
              target="_blank"
              rel="noreferrer"
              className="bg-white px-3 sm:px-7 md:!px-10 py-2 md:py-3 rounded-lg text-sm md:text-base font-bold"
            >
              Live chat now
            </a>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default MeetingForm;
