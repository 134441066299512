import { useState } from "react";

import Accordion from "./Accordion";

const LeftContent = ({ data }) => {
  const [isReadMore, setIsReadMore] = useState(
    window.screen.width <= 768 ? true : false
  );

  const ReadMore = ({ children }) => {
    const text = children;
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="my-2 md:my-4 lg:my-5 font-light leading-8 sm:pr-8 md:pr-12 lg:pr-16">
        {isReadMore ? text.slice(0, 150) : text}
        <span onClick={toggleReadMore} className="font-semibold">
          {isReadMore ? "...read more" : " show less"}
        </span>
      </p>
    );
  };

  return (
    <div className="text-white">
      <div>
        <h3 className="text-xl sm:text-2xl lg:text-3xl mb-2 md:mb-4 font-semibold text-[red]">
          Property Highlights
        </h3>
        {data ? (
          <ReadMore>{data.Description}</ReadMore>
        ) : (
          <div className="animate-pulse flex flex-col gap-4">
            <span className="py-2 bg-gray-300 rounded-md"></span>
            <span className="py-2 bg-gray-300 rounded-md"></span>
            <span className="py-2 bg-gray-300 rounded-md"></span>
            <span className="py-2 bg-gray-300 rounded-md"></span>
          </div>
        )}
      </div>
      {data && <Accordion data={data.unitTypes} />}
    </div>
  );
};

export default LeftContent;
