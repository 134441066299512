import React from "react";

const Page404 = () => {
  return (
    <div className="p-4 text-lg text-white">
      <h1>404 Not Found</h1>
      <p>Oops! The page you're looking for does not exist.</p>
    </div>
  );
};

export default Page404;
