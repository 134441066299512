import { useState, useEffect } from "react";
import { useParams } from "react-router";
import http from "../../utils/http";
import toast from "react-hot-toast";
import PhoneInput from "react-phone-input-2";

import PropertySlider from "./PropertySlider";
import PropertyCard from "./PropertyCard";
import LeftContent from "./LeftContent";
import RightContent from "./RightContent";
import ContactUs from "./ContactUs";
import Modal from "../../components/UI/Modal";

const Property = () => {
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [propertyData, setPropertyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [developer, setDeveloper] = useState(null);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("+20");
  const params = useParams();

  const getPropertyById = () => {
    setLoading(true);
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getPropertyById/${params.id}`)
      .then((response) => {
        const data = response.data[0];
        if (response.status === 200) {
          const element = data;
          const list = {
            id: element._id,
            img: element.Images?.length > 0 && element.Images[0],
            imgList: element.Images,
            title: element.Name,
            Project_Developer: element.Project_Developer,
            price: element.Price,
            continent: element.State,
            type: element.Type,
            link: element.Link,
            location: `${element.City}, ${element.Country}`,
            InstallmentYears: `${element.InstallmentYears} Years`,
            Delivery: `${element.Delivery}`,
            DownPayment: element.DownPayment,
            area: `${element.Area} m²`,
            unitTypes: element.Unit_PropertyType,
            amenities: element._Amenities,
            Description: element.Description,
          };

          setPropertyData(list);
          setLoading(false);
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));
  };

  const getLocations = () => {
    setLoading(true);
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getLocations/`)
      .then((response) => {
        if (response.status === 200) {
          setLocations(response.data.results);
        } else toast.error(response?.data?.error?.message);
        setLoading(false);
      })
      .catch((err) => toast.error(err.message));
  };

  const getProjectDeveloperByID = () => {
    http
      .get(
        `${process.env.REACT_APP_ATLAS_URI}/getProjectDeveloperByID/${propertyData.Project_Developer}`
      )
      .then((response) => {
        if (response.status === 200) {
          const results = response?.data;
          setDeveloper(results);
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));
  };

  const addContactHandler = (e) => {
    e.preventDefault();
    http
      .post(`${process.env.REACT_APP_ATLAS_URI}/addContact/`, {
        Name: fName + " " + lName,
        PropertyName: propertyData.title,
        PhoneNumber: phone,
        Message: "Sent from property",
        Date: new Date(),
        Time: new Date(),
      })
      .then((response) => {
        if (response.status === 200) {
          setFName("");
          setLName("");
          setPhone("+20");
          setShowMessage(true);
          toast.success(response?.data?.message);
        } else toast.error(response.data.error.message);
      })
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    if (params.id) {
      getPropertyById();
    }
    getLocations();
  }, []);

  useEffect(() => {
    if (propertyData) {
      getProjectDeveloperByID();
    }
  }, [propertyData]);

  useEffect(() => {
    window.scrollTo(0, 0);

    // Appearance of modal
    setTimeout(() => {
      setShowModal(true);
    }, 7000);
  }, []);

  const numberedId = +params.id;

  if (isNaN(numberedId)) {
    return (
      <p className="text-2xl text-white m-auto text-center mt-12 font-avant-grade-md border-2 w-fit p-6 rounded-md">
        Invalid property filters. Please adjust your values!
      </p>
    );
  }

  if (!propertyData && !loading)
    return (
      <p className="text-2xl text-white m-auto text-center mt-12 font-avant-grade-md border-2 w-fit p-6 rounded-md">
        Oops! Could not find property
      </p>
    );

  return (
    <div className={`${showModal && "h-[85vh] overflow-hidden"}`}>
      <div className="grid grid-cols-1 lg:grid-cols-[60%,40%] font-avant-grade-semibold tracking-widest mb-4 sm:mb-0">
        <PropertySlider data={propertyData} />
        <PropertyCard
          data={propertyData}
          setShowModal={setShowModal}
          developer={developer}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8 lg:gap-12 px-2 sm:px-10 md:px-16 my-0 sm:my-6 md:my-10 lg:my-16">
        <LeftContent data={propertyData} />
        <RightContent data={propertyData} />
      </div>
      <ContactUs
        property={propertyData}
        setShowMessage={setShowMessage}
        setShowModal={setShowModal}
      />
      {!showMessage && showModal ? (
        <Modal
          onClose={() => setShowModal(false)}
          className="z-[500] !absolute !left-1/2 !top-1/2 !-translate-x-1/2 !-translate-y-1/2 !rounded-md !overflow-hidden pb-4 !w-fit md:!w-[68%] lg:!w-[55%] xl:!w-1/2 2xl:!w-2/5 !h-fit"
        >
          <span
            className="float-right text-[red] text-2xl font-semibold cursor-pointer mr-4"
            onClick={() => setShowModal(false)}
          >
            x
          </span>
          <form
            className="w-full bg-white flex flex-col items-center gap-2 md:items-left px-8 md:px-6"
            onSubmit={addContactHandler}
          >
            <div className="mb-2 md:text-lg font-semibold text-center">
              <p className="">Downlad Your '{propertyData.title}' Brochure</p>
              <p className="">including Prices, Amenities, and Maps</p>
            </div>
            <div className="grid md:grid-cols-2 justify-center sm:justify-start gap-6 md:gap-8 lg:gap-12 w-full mb-7 md:mb-2 lg:mb-4">
              <div className="flex flex-col w-[14rem] sm:w-[15rem] xl:w-[17rem]">
                <label htmlFor="fname" className="sm:text-lg">
                  {" "}
                  First Name
                </label>
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  className="px-2 py-1 sm:text-lg w-full border-b-2 border-[red] outline-none"
                  placeholder="Enter your First Name"
                  value={fName}
                  onChange={(e) => setFName(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-[14rem] sm:w-[15rem] xl:w-[17rem]">
                <label htmlFor="lname" className="sm:text-lg">
                  {" "}
                  Last Name
                </label>
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  className="px-2 py-1 sm:text-lg w-full border-b-2 border-[red] outline-none"
                  placeholder="Enter your First Name"
                  value={lName}
                  onChange={(e) => setLName(e.target.value)}
                />
              </div>
              <div className="col-span-2 self-center justify-self-center">
                <div className="flex flex-col justify-center gap-2 relative mb-4 w-[15rem] xl:w-[22rem] overflow-hidden">
                  <label htmlFor="phone" className="md:text-lg top-0 z-10 px-1">
                    Phone Number
                  </label>
                  <PhoneInput
                    id={"phone"}
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    containerClass="!border-0 !w-full"
                    buttonClass="text-lg !pl-2 bg-transparent !border-0 !border-b-2 !border-[red]"
                    inputClass="!bg-transparent !py-[1.2rem] !px-[3.5rem] !pr-0 sm:!text-lg !border-t-0 !border-l-0 !border-r-0 !border-b-2 !border-[red] !rounded-none !w-full"
                  />
                </div>
              </div>
            </div>
            <button className="sm:text-lg px-6 sm:px-10 py-2 bg-[red] text-white w-fit font-semibold rounded-md mx-auto">
              Download
            </button>
          </form>
        </Modal>
      ) : showMessage && showModal ? (
        <Modal
          onClose={() => setShowModal(false)}
          className="!absolute !left-1/2 !top-1/2 !-translate-x-1/2 !-translate-y-1/2 !rounded-md !overflow-hidden pb-4 !w-10/12 sm:!w-[62%] md:!w-[50%] lg:!w-[45%] xl:!w-2/5 2xl:!w-1/3 !h-fit"
        >
          <span
            className="float-right text-[red] text-2xl font-semibold cursor-pointer mr-4"
            onClick={() => setShowModal(false)}
          >
            x
          </span>
          <div className="flex flex-col gap-8 py-12 text-center mx-6 sm:px-12 md:px-0">
            <div className="flex flex-col gap-1 text-lg sm:text-xl leading-2">
              <p className="">Thank you for contacting us</p>
              <p className="">We will reach you soon.</p>
            </div>
            <button
              className="text-lg px-10 py-2 bg-[red] text-white w-fit font-semibold rounded-md mx-auto cursor-pointer"
              onClick={() => {
                setShowModal(false);
                setShowMessage(false);
              }}
            >
              Close
            </button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};
export default Property;
