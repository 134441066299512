import React from "react";

const RightContent = ({ data }) => {
  const features = data?.amenities;

  return (
    <div className="text-white relative">
      <h3 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-[red]">
        Features
      </h3>
      <ul className="grid grid-cols-2 sm:grid-cols-3 gap-4 w-full my-6 text-lg">
        {data
          ? features.map((feature, index) => (
              <li key={index}>
                <span>{feature.Name}</span>
              </li>
            ))
          : [...Array(6)].map((_, index) => (
              <li
                className="py-3 px-12 bg-gray-300 rounded-md w-fit"
                key={index}
              ></li>
            ))}
      </ul>
      {data && (
        <div className="relative w-full h-[450px]">
          <div
            dangerouslySetInnerHTML={{ __html: data.link }}
            className="!w-full overflow-hidden myMap absolute"
          />
        </div>
      )}

      {/* <iframe
        title="map"
        src={props.data.link}
        width="100%"
        height="386"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe> */}
    </div>
  );
};
export default RightContent;
