import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, EffectFade } from "swiper";

import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const PropertySlider = ({ data }) => {
  return (
    <div className="relative w-full overflow-hidden">
      <Swiper
        pagination={{
          clickable: true,
        }}
        spaceBetween={30}
        effect={"fade"}
        modules={[Autoplay, EffectFade, Pagination]}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {data?.imgList
          ? data.imgList.map((data, index) => {
              return (
                <SwiperSlide className="" key={index}>
                  <img
                    src={`${process.env.REACT_APP_ATLAS_URI}/file/${data}`}
                    alt="property"
                    className="w-full h-[16rem] sm:h-[18rem] md:h-[20rem]  lg:h-[32.5rem]"
                  />
                </SwiperSlide>
              );
            })
          : [...Array(3)]?.map((data, index) => {
              return (
                <SwiperSlide className="" key={index}>
                  <div className="animate pulse w-full h-[16rem] sm:h-[18rem] md:h-[20rem]  lg:h-[32.5rem] bg-gray-600" />
                </SwiperSlide>
              );
            })}
      </Swiper>
    </div>
  );
};
export default PropertySlider;
