import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

import RightAngle from "../UI/RightAngle";
import { SelectContext } from "../../context/user-select";
import { useContext } from "react";

const SlidingContent = (props) => {
  const [searchInput, setSearchInput] = useState("");

  const selectContext = useContext(SelectContext);
  const { value, setValueHandler } = selectContext;

  const [isVisible, setIsVisible] = useState(true);

  const navigate = useNavigate();

  const content = props.content;

  const toggleContent = () => {
    setIsVisible((prev) => !prev);
  };

  const searchChangeHandler = (e) => {
    setSearchInput(e.target.value);
  };

  const onClickHandler = (link, data) => {
    const dataWithPageId = { ...data, pageId: content.pageId };
    setValueHandler(dataWithPageId);
    if (content.input) {
      setValueHandler({ searchInput });
    }
    navigate(`/${link}`, {
      state: data,
    });
  };

  return (
    <AnimatePresence>
      <RightAngle />
      <div
        className={`w-full overflow-hidden font-avant-grade-bold tracking-wider ${
          !content.subtitle ? "mt-16 sm:mt-24 md:mt-36" : "mt-16 md:mt-24"
        }`}
        key="select"
      >
        <motion.div
          className={`flex flex-col items-center justify-center mx-auto w-10/12 md:w-[50%] text-center transition-all duration-300 z-0`}
          initial={{ transform: "translateX(100%)" }}
          animate={{ transform: "translateX(0%)" }}
          exit={{ transform: "translateX(100%)" }}
          transition={{ duration: 0.1, ease: "easeOut" }}
        >
          <div className="text-[white] text-center w-full">
            <div className="flex flex-col gap-0 w-full items-center">
              <h2 className="font-bold text-xl sm:text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl w-[16rem] sm:w-[22rem] md:w-[27.3rem] lg:w-[38rem] lg:!leading-[3rem]">
                {content.title}
              </h2>
              {content.input && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    navigate(`/search-property`, {
                      state: searchInput,
                    });
                  }}
                  className="grid grid-cols-[4fr,1fr] justify-center w-[90%] sm:w-[80%] lg:w-[70%] my-4 md:my-9 lg:my-16"
                >
                  <input
                    value={searchInput}
                    type="text"
                    id="visitors"
                    className="block font-cairo font-medium w-full px-3 md:px-6 py-[0.9rem] text-sm bg-gray-50 outline-none  border-gray-300 text-gray-900 rounded-l-full"
                    placeholder="search city, project or developer..."
                    onChange={searchChangeHandler}
                    required
                  />
                  <button
                    type="submit"
                    onClick={() =>
                      navigate(`/search-property`, {
                        state: searchInput,
                      })
                    }
                    className="flex items-center justify-center p-2.5 bg-[red] rounded-r-full w-full"
                  >
                    <i className="fas fa-search text-[1.1rem]"></i>
                  </button>
                </form>
              )}
            </div>
            {content.subtitle && (
              <div
                className={`${
                  !content.input && "mt-3 sm:mt-6 lg:mt-10"
                }  text-gray-300 text-base sm:text-lg md:text-xl font-avant-grade-semibold`}
              >
                {content.subtitle}
              </div>
            )}
          </div>

          <div
            className={`w-3/5 sm:w-1/2 md:w-1/2 xl:w-[30%] ${
              content.input ? "mt-4 md:mt-6 lg:mt-8" : "mt-12"
            } `}
          >
            <button
              id="dropdownDefault"
              data-dropdown-toggle="dropdown"
              className="flex justify-between text-gray-400 bg-zinc-50 focus:outline-none font-bold rounded-full text-sm px-5 py-3 text-center  items-center w-full"
              type="button"
              onClick={() => {
                toggleContent();
              }}
            >
              Choose One{" "}
              <svg
                className="ml-2 w-4 h-4"
                aria-hidden="true"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>
            {/* Dropdown menu  */}
            <div
              id="dropdown"
              className={`${
                isVisible ? "visible" : "invisible"
              } font-bold mt-2 z-10 bg-white rounded-xl divide-y divide-gray-100 w-full h-[165px] md:h-[180px] overflow-y overflow-auto`}
            >
              <ul
                className="py-1 text-sm text-gray-700"
                aria-labelledby="dropdownDefault"
              >
                {props.isFetched ? (
                  content.lis.map((item, index) => {
                    return (
                      <li key={index} className="my-1 md:my-2">
                        <button
                          value={item}
                          onClick={(e) =>
                            onClickHandler(content.nextLink, item)
                          }
                          className="block py-2 px-4 hover:text-red-600 cursor-pointer"
                        >
                          {item.Name}
                        </button>
                      </li>
                    );
                  })
                ) : (
                  <li className="my-1 md:my-2">No Data to show</li>
                )}
              </ul>
            </div>
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
export default SlidingContent;
