import Contact from "../../components/UI/Contact";
import { fCurrency } from "../../utils/formatNumber";
import yearsOnInstallment from "../../static/icons/years-on-installment.png";

const PropertyCard = ({ data, setShowModal, developer }) => {
  return data ? (
    <div
      className={`relative px-4 sm:px-8 xl:px-12 py-3 md:py-4 lg:py-7 rounded-md font-gillsans mx-auto min-h-[310px] sm:min-h-[330px] w-full text-white`}
    >
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mt-4 md:mt-5 lg:mt-8 mb-2">
        {data.title}
      </h2>
      <span className="text-gray-400 font-avant-grade-bold md:text-lg">
        {developer?.Name || ""}
      </span>
      <div className="flex flex-col gap-0 mt-2 sm:my-3 md:my-4 lg:my-5">
        <h3 className="text-2xl sm:text-3xl lg:text-4xl font-bold text-[red]">
          {fCurrency(data.price)}
        </h3>
        <span className="text-2xl md:text-3xl mt-2">{data.type || ""}</span>
      </div>
      <div className="relative flex flex-col gap-4 md:gap-5 lg:gap-7 mt-0 lg:mt-7">
        <div className="space-y-3 lg:space-y-4">
          <div className="flex items-center gap-2 -left-1 lg:left-0">
            {/* location icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.3}
              stroke="#ffffff"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
            <span className="text-sm sm:text-base">{data.location}</span>
          </div>
          <div className="flex gap-2 sm:gap-3 w-full font-avant-grade-md">
            <div className="flex items-center gap-2 pr-2">
              <img
                src={yearsOnInstallment}
                alt="Intallment Years"
                className="w-7 md:w-8 lg:w-10 bg-transparent invert"
              />
              <span className="md:text-lg">{data.InstallmentYears}</span>
            </div>
            <div className="flex items-center gap-2 font-avant-grade-md">
              <i className="fa-solid fa-calculator text-lg md:text-xl lg:text-2xl text-gray-300"></i>
              <span className="md:text-lg">{data.DownPayment}%</span>
            </div>
          </div>
          <div className="flex items-center gap-2 pl-2 w-full font-avant-grade-md">
            <i className="fas fa-calendar-alt text-lg md:text-xl lg:text-2xl text-gray-300"></i>
            <span className="md:text-lg">{data.Delivery}</span>
          </div>
        </div>
        <Contact setShowModal={setShowModal} />
      </div>
    </div>
  ) : (
    <div
      className={`animate-pulse relative px-4 sm:px-8 xl:px-12 py-5 sm:py-7 rounded-md font-gillsans mx-auto min-h-[400px] w-full text-white shadow-sm shadow-white`}
    >
      <div className="text-5xl font-bold mt-5 sm:mt-8 py-7 bg-gray-300 rounded-md px-24 w-fit"></div>
      <div className="text-gray-400 font-avant-grade-bold mt-6 py-3 px-16 bg-gray-300 rounded-md w-fit"></div>
      <div className="text-2xl sm:text-3xl lg:text-4xl mt-5 bg-gray-300 py-6 rounded-md px-28 w-fit"></div>

      <div className="relative flex flex-col gap-7 lg:mt-7">
        <div className="space-y-4">
          <div className="flex items-center gap-2 -left-1 mt-4 lg:left-0">
            {/* location icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="gray"
              viewBox="0 0 24 24"
              strokeWidth={1.3}
              stroke=""
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
              />
            </svg>
            <span className="text-sm sm:text-base py-2 bg-gray-300 w-24 rounded-md" />
          </div>
          <div className="flex gap-2 sm:gap-4 w-full">
            <div className="flex items-center gap-2 py-2 pr-2">
              <span className="w-5 md:w-6 h-5 md:h-6 bg-transparent rounded-md bg-gray-300" />
              <span className="py-2 px-6 bg-gray-300 rounded-md"></span>
            </div>
            <div className="flex items-center gap-2 py-2 pr-2">
              <div className="relative">
                <i className="fa-solid fa-calculator text-lg md:text-xl text-gray-300"></i>
              </div>
              <span className="py-2 px-4 bg-gray-300 rounded-md"></span>
            </div>
          </div>
          <div className="relative flex items-center gap-2 pl-2 w-full">
            <i className="fas fa-calendar-alt text-xl text-gray-300"></i>
            <span className="py-2 px-4 bg-gray-300 rounded-md"></span>
          </div>
        </div>
        <div className="flex gap-4 justify-center">
          <span className="py-4 px-16 bg-gray-300 rounded-3xl"></span>
          <span className="py-4 px-16 bg-gray-300 rounded-3xl"></span>
          <span className="py-4 px-16 bg-gray-300 rounded-3xl"></span>
        </div>
      </div>
    </div>
  );
};
export default PropertyCard;
