import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import http from "../../utils/http";
import toast from "react-hot-toast";

import SliderCard from "./SliderCard";
import yearsOnInstallment from "../../static/icons/years-on-installment.png";
import { SelectContext } from "../../context/user-select";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const Slider = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [uploadedDevelopers, setUploadedDevelopers] = useState(null);
  const [developers, setDevelopers] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(window.screen.width <= 768 ? 1 : 4);

  const navigate = useNavigate();

  const [pageNumbers, setPageNumbers] = useState([]);

  const selectContext = useContext(SelectContext);
  const { value } = selectContext;

  const getProjectDevelopers = () => {
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/getProjectDevelopers/`)
      .then((response) => {
        if (response.status === 200) {
          const results = response?.data?.results;
          setUploadedDevelopers(results);
        } else toast.error(response?.data?.error?.message);
      })
      .catch((err) => toast.error(err.message));
  };

  const getProperties = () => {
    setLoading(true);
    http
      .get(`${process.env.REACT_APP_ATLAS_URI}/searchProperty/`, {
        params: {
          limit: limit,
          page: pageNo,
          name: value.length > 3 && value[1].searchInput,
          location: value.length > 3 && value[0]._id,
          unitType: value.length > 3 && value[2]._id,
          price: value.length > 3 && value[3]._id,
        },
      })
      .then((response) => {
        const data = response.data.results;
        if (response.status === 200) {
          setPageNumbers(
            Array.from(
              { length: Math.ceil(response.data.count / limit) },
              (v, k) => k + 1
            )
          );

          const list = [];
          const _developers = [];
          data.forEach((element) => {
            const developer = uploadedDevelopers.find(
              (d) => d._id === element.Project_Developer
            );
            _developers.push(developer);
            list.push({
              id: element._id,
              img: element.Images?.length > 0 && element.Images[0],
              imgList: element.Images,
              title: element.Name,
              subtitle: element.Type,
              price: `EGP ${element.Price}`,
              continent: element.State,
              type: element.Type,
              link: element.Link,
              location: `${element.City}, ${element.Country}`,
              InstallmentYears: `${element.InstallmentYears} Years`,
              Delivery: `${element.Delivery}`,
              DownPayment: `${element.DownPayment}%`,
              area: `${element.Area} m²`,
              unitTypes: element.Unit_PropertyType,
              projectDeveloper: element.Project_Developer,
              amenities: element._Amenities,
            });
          });
          setDevelopers(_developers);
          setData(list);
          setLoading(false);
        } else {
          toast.error(response?.data?.error?.message);
          setLoading(false);
        }
      })
      .catch((err) => {
        toast.error(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProjectDevelopers();
  }, []);

  useEffect(() => {
    if (uploadedDevelopers) {
      getProperties();
    }
  }, [uploadedDevelopers, pageNo]);

  const leftClickHandler = () => {
    setLoading(true);
    setData(null);
    setPageNo((prev) => prev - 1);
  };

  const rightClickHandler = () => {
    setLoading(true);
    setData(null);
    setPageNo((prev) => prev + 1);
  };

  const selectPageHandler = (index) => {
    setLoading(true);
    setData(null);
    setPageNo(index + 1);
  };

  return (
    <div className="relative px-2 sm:px-6 md:px-10 lg:px-16 z-0 mt-6 md:mt-8 lg:mt-16">
      <div>
        <div className="text-white text-center w-full font-avant-grade-bold">
          <h2 className="font-bold text-xl md:text-2xl lg:text-3xl mb-4 lg:mb-6 px-4 sm:p-0">
            HERE ARE THE <span className="text-[red]">PROJECTS</span> THAT SUIT
            YOUR NEEDS
          </h2>
          <p className="mb-2 lg:mb-4 text-gray-300 text-lg md:text-xl">
            Select one of the options below
          </p>
        </div>
        <div className="flex items-center gap-4">
          {data && data.length !== 0 && (
            <i
              className="fa fa-chevron-left fa-2xl text-[red] cursor-pointer"
              aria-hidden="true"
              onClick={leftClickHandler}
            ></i>
          )}

          {data && data.length !== 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full mt-4">
              {data &&
                data.map((data, index) => {
                  return (
                    <SliderCard
                      data={data}
                      projectDeveloper={developers[index]}
                      key={index}
                      className="!w-full"
                    />
                  );
                })}
            </div>
          ) : !data && loading ? (
            <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4 w-full mt-4">
              {[...Array(6)].map((val, index) => (
                <div
                  className={`animate-pulse bg-transparent border border-gray-300 px-4 py-7 rounded-md mx-auto w-full font-avant-grade-semibold`}
                  key={index}
                >
                  <div className="w-full h-[180px] rounded-md bg-gray-300"></div>
                  <div className="px-4">
                    <div className="flex flex-col w-full mt-4 gap-2 font-avant-grade-bold">
                      <div className="flex justify-between items-center">
                        <h2 className="text-xl font-bold py-4 bg-gray-300 w-36 rounded-md"></h2>
                      </div>
                      <span className="text-gray-500 py-3 bg-gray-300 rounded-md w-24"></span>
                    </div>
                    <div className="text-xl sm:text-2xl font-bold bg-gray-300 py-6 rounded-md px-24 mt-4 w-fit"></div>
                    <div className="flex gap-0 sm:gap-2 my-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="gray"
                        viewBox="0 0 24 24"
                        strokeWidth={0}
                        stroke=""
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                        />
                      </svg>
                      <span className="text-sm sm:text-base py-2 bg-gray-300 w-24 rounded-md"></span>
                    </div>
                    <div className="flex gap-2 sm:gap-4 w-full">
                      <div className="flex items-center gap-2 py-2 pr-2">
                        <span className="w-6 h-6 bg-transparent rounded-md bg-gray-300" />
                        <span className="py-2 px-6 bg-gray-300 rounded-md"></span>
                      </div>
                      <div className="flex items-center gap-2 py-2 pr-2">
                        <div className="relative">
                          <i className="fa-solid fa-calculator text-xl text-gray-300"></i>
                        </div>
                        <span className="py-2 px-4 bg-gray-300 rounded-md"></span>
                      </div>
                    </div>
                    <div className="relative flex items-center gap-2 pl-2 w-full">
                      <i className="fas fa-calendar-alt text-xl text-gray-300"></i>
                      <span className="py-2 px-4 bg-gray-300 rounded-md"></span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full justify-center md:mt-12">
              <div className="text-white text-center w-full font-avant-grade-bold text-3xl pt-3">
                No Results Found
              </div>
              <div className="my-6  text-white text-xl text-center font-avant-grade-bold">
                Your search preferences have{" "}
                <span className="text-red-600">(0) results</span>.
              </div>
              <div className="font-bold text-center text-3xl text-white w-full">
                <Link to="/">Try again? </Link>
              </div>
            </div>
          )}
          {data && data.length !== 0 && (
            <i
              className="fa fa-chevron-right fa-2xl text-[red] cursor-pointer"
              aria-hidden="true"
              onClick={rightClickHandler}
            ></i>
          )}
        </div>

        {/* Page Numbers */}
        <div className="flex items-center justify-center gap-4 w-full mt-4">
          {pageNumbers
            .slice(
              pageNo + 8 > pageNumbers.length
                ? pageNumbers.length - 8 >= 0
                  ? pageNumbers.length - 8
                  : 0
                : pageNo - 1,

              pageNo + 8 > pageNumbers.length ? pageNumbers.length : pageNo + 8
            )
            .map((pageNumber, index) => {
              return (
                <button
                  key={index}
                  className={`${
                    pageNo === pageNumber
                      ? "bg-[red] text-gray-50"
                      : "bg-gray-50 text-[#212020]"
                  } rounded-full font-semibold w-3 h-3 text-[8px]`}
                  onClick={() => selectPageHandler(pageNumber - 1)}
                >
                  {pageNumber}
                </button>
              );
            })}
        </div>
      </div>
    </div>
  );
};
export default Slider;
