import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="fixed bottom-0 py-2 flex items-start sm:items-center justify-center w-full gap-4 sm:gap-8 md:gap-12 lg:gap-24 xl:gap-32 bg-[#212020] z-[80] font-cairo font-medium">
      <a
        href="tel:+201012995408"
        target="_blank"
        rel="noreferrer"
        className="flex flex-col items-center justify-center w-[8rem]"
      >
        <span>
          <i className="fa-solid fa-headset text-gray-400 text-2xl"></i>
        </span>
        <span className="text-gray-300 text-xs">Call Us</span>
      </a>
      <a
        href="https://wa.me/201012995408?text=Lets%20chat!%20-%20from%20Byoutak"
        target="_blank"
        rel="noreferrer"
        className="flex flex-col items-center justify-center w-[8rem]"
      >
        <i className="fas fa-comment-dots text-gray-400 text-2xl"></i>
        <span className="text-gray-300 text-xs">Message Us</span>
      </a>
      <Link
        to="/meeting"
        className="flex flex-col text-center items-center justify-center w-[8rem]"
      >
        <span>
          <i className="fas fa-user text-gray-400 text-2xl"></i>
        </span>
        <span className="text-gray-300 text-xs w-[5rem] sm:w-auto">
          Schedule a Meeting
        </span>
      </Link>
    </footer>
  );
};
export default Footer;
